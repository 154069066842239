import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';
import { MentionsInput, Mention } from 'react-mentions';
import { useDidUpdate, useToggle } from '../../../lib/hooks';
import { useClosableForm, useForm } from '../../../hooks';
import styles from './CommentAdd.module.scss';
import User from '../../User';

const DEFAULT_DATA = {
  text: '',
};

const renderUserSuggestion = (entry, search, highlightedDisplay, index, focused) => {
  return (
    <div className={`${styles.suggestionItem} ${focused ? styles.focused : ''}`} key={entry.id}>
      <User name={entry.display} avatarUrl={entry.profilePicture} size="large" />
      <div className={styles.userInfo}>
        <div className={styles.name}>{entry.display}</div>
        <div className={styles.username}>{entry.username}</div>
      </div>
    </div>
  );
};

const displayTransform = (id, display) => {
  return `  @${display}  `
};

const CommentAdd = React.memo(({ onCreate, mentionUsers }) => {
  const [t] = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const [data, setData] = useState(DEFAULT_DATA);
  const [selectTextFieldState, selectTextField] = useToggle();

  const textField = useRef(null);

  const close = useCallback(() => {
    // setIsOpened(false);
  }, []);

  const submit = useCallback(() => {
    const cleanData = {
      ...data,
      text: data.text.trim(),
    };

    if (!cleanData.text) {
      textField.current.focus();
      return;
    }

    onCreate(cleanData);

    setData(DEFAULT_DATA);
    selectTextField();
  }, [onCreate, data, setData, selectTextField]);

  const handleFieldChange = (event, newValue) => {
    setData({ ...data, text: newValue });
  };

  const handleFieldFocus = useCallback(() => {
    setIsOpened(true);
  }, []);

  const handleFieldKeyDown = useCallback(
    (event) => {
      if (event.ctrlKey && event.key === 'Enter') {
        submit();
      }
    },
    [submit],
  );

  const [handleFieldBlur, handleControlMouseOver, handleControlMouseOut] = useClosableForm(close);

  const handleSubmit = useCallback(() => {
    submit();
  }, [submit]);

  useDidUpdate(() => {
    textField.current.focus();
  }, [selectTextFieldState]);

  return (
    <Form onSubmit={handleSubmit} style={{ position: 'relative', overflow: 'visible' }}>
      <MentionsInput
        value={data.text}
        onChange={(event, newValue) => handleFieldChange(event, newValue)}
        inputRef={textField}
        placeholder={t('common.writeComment')}
        spellCheck={false}
        className='mentions'
        classNames={styles}
        onFocus={handleFieldFocus}
        onKeyDown={handleFieldKeyDown}
        onBlur={handleFieldBlur}
        name='text'
        style={{ width: '100%'}}
      >
        <Mention
          trigger="@"
          className={styles.mentions__mention}
          data={mentionUsers}
          renderSuggestion={renderUserSuggestion}
          markup='[@__display__](/member/__id__)'
          displayTransform={displayTransform}
          appendSpaceOnAdd="true"
          style={{ zIndex: 1000000}}
        />
      </MentionsInput>
      {isOpened && (
        <div className={styles.controls}
            style={{ zIndex: 100, isolation: "isolate"}}
        >
          <Button
            positive
            content={t('action.addComment')}
            onMouseOver={handleControlMouseOver}
            onMouseOut={handleControlMouseOut}
          />
        </div>
      )}


    </Form>
  );
});

CommentAdd.propTypes = {
  onCreate: PropTypes.func.isRequired,
  mentionUsers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    profilePicture: PropTypes.string,
  })).isRequired,
};

export default CommentAdd;

