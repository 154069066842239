import { attr, fk } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';

export default class extends BaseModel {
  static modelName = 'ProjectMember';

  static fields = {
    id: attr(),
    createdAt: attr({
      getDefault: () => new Date(),
    }),
    isAdminInherited: attr({
      getDefault: () => false,
    }),
    projectId: fk({
      to: 'Project',
      as: 'project',
      relatedName: 'members',
    }),
    userId: fk({
      to: 'User',
      as: 'user',
      relatedName: 'projectMembers',
    }),
  };

  static reducer({ type, payload }, ProjectMember) {
    switch (type) {
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        ProjectMember.all().delete();

        payload.projectMembers.forEach((projectMember) => {
          ProjectMember.upsert(projectMember);
        });

        break;
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROJECT_CREATE__SUCCESS:
      case ActionTypes.PROJECT_CREATE_HANDLE:
        payload.projectMembers.forEach((projectMember) => {
          ProjectMember.upsert(projectMember);
        });

        break;
      case ActionTypes.PROJECT_MEMBER_CREATE:
        ProjectMember.upsert(payload.projectMember);

        break;
      case ActionTypes.PROJECT_MEMBER_CREATE__SUCCESS:
        ProjectMember.withId(payload.localId).delete();
        ProjectMember.upsert(payload.projectMember);

        break;
      case ActionTypes.PROJECT_MEMBER_CREATE_HANDLE:
        ProjectMember.upsert(payload.projectMember);

        if (payload.projectMembers) {
          payload.projectMembers.forEach((projectMember) => {
            ProjectMember.upsert(projectMember);
          });
        }

        break;
      case ActionTypes.PROJECT_MEMBER_DELETE:
        ProjectMember.withId(payload.id).delete();

        break;
      case ActionTypes.PROJECT_MEMBER_DELETE__SUCCESS:
      case ActionTypes.PROJECT_MEMBER_DELETE_HANDLE: {
        const projectMemberModel = ProjectMember.withId(payload.projectMember.id);

        if (projectMemberModel) {
          projectMemberModel.delete();
        }

        break;
      }
      case ActionTypes.BOARD_MEMBERSHIP_CREATE_HANDLE:
        if (payload.projectMembers) {
          payload.projectMembers.forEach((projectMember) => {
            ProjectMember.upsert(projectMember);
          });
        }

        break;
      default:
    }
  }
}
