import { dequal } from 'dequal';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { MentionsInput, Mention } from 'react-mentions';
import { useTranslation } from 'react-i18next';
import { Button, Form, TextArea } from 'semantic-ui-react';

import { useClosableForm, useForm } from '../../../hooks';
import User from '../../User';
import { focusEnd } from '../../../utils/element-helpers';

import styles from './CommentEdit.module.scss';

const renderUserSuggestion = (entry, search, highlightedDisplay, index, focused) => {
  return (
    <div className={`${styles.suggestionItem} ${focused ? styles.focused : ''}`} key={entry.id}>
      <User name={entry.display} avatarUrl={entry.profilePicture} size="large" />
      <div className={styles.userInfo}>
        <div className={styles.name}>{entry.display}</div>
        <div className={styles.username}>{entry.username}</div>
      </div>
    </div>
  );
};

const displayTransform = (id, display) => {
  return `  @${display}  `;
};

const DEFAULT_DATA = {
  text: '',
};

const CommentEdit = React.forwardRef(({ children, defaultData, onUpdate, mentionUsers }, ref) => {
  const [t] = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const [data, setData] = useState(DEFAULT_DATA);

  const handleFieldChange = (event, newValue) => {
    setData({ ...data, text: newValue });
  };

  const textField = useRef(null);

  const open = useCallback(() => {
    setIsOpened(true);
    setData({
      text: '',
      ...defaultData,
    });
  }, [defaultData, setData]);

  const close = useCallback(() => {
    setIsOpened(false);
    setData(null);
  }, [setData]);

  const submit = useCallback(() => {
    const cleanData = {
      ...data,
      text: data.text.trim(),
    };

    if (cleanData.text && !dequal(cleanData, defaultData)) {
      onUpdate(cleanData);
    }

    close();
  }, [defaultData, onUpdate, data, close]);

  useImperativeHandle(
    ref,
    () => ({
      open,
      close,
    }),
    [open, close],
  );

  const handleFieldKeyDown = useCallback(
    (event) => {
      if (event.ctrlKey && event.key === 'Enter') {
        submit();
      }
    },
    [submit],
  );

  // const handleFieldBlur = useCallback(() => {
  //   submit();
  // }, [submit]);

  const handleFieldFocus = useCallback(() => {
    setIsOpened(true);
  }, []);

  // const [handleControlMouseOver, handleControlMouseOut] = useClosableForm(close);

  const handleSubmit = useCallback(() => {
    submit();
  }, [submit]);

  useEffect(() => {
    if (isOpened) {
      // focusEnd(textField.current.ref.current);
    }
  }, [isOpened]);

  if (!isOpened) {
    return children;
  }

  return (
    <Form onSubmit={handleSubmit} style={{ position: 'relative', overflow: 'visible' }}>
      <MentionsInput
        value={data.text}
        onChange={(event, newValue) => handleFieldChange(event, newValue)}
        inputRef={textField}
        placeholder={t('common.writeComment')}
        spellCheck={false}
        className="mentions"
        classNames={styles}
        onFocus={handleFieldFocus}
        onKeyDown={handleFieldKeyDown}
        // onBlur={handleFieldBlur}
        name="text"
        style={{ width: '100%' }}
      >
        <Mention
          trigger="@"
          className={styles.mentions__mention}
          data={mentionUsers}
          renderSuggestion={renderUserSuggestion}
          markup="[@__display__](/member/__id__)"
          displayTransform={displayTransform}
          appendSpaceOnAdd="true"
        />
      </MentionsInput>
      {isOpened && (
        <div className={styles.controls}>
          <Button positive content={t('action.addComment')} />
        </div>
      )}
    </Form>
  );
});

CommentEdit.propTypes = {
  children: PropTypes.element.isRequired,
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onUpdate: PropTypes.func.isRequired,
  mentionUsers: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default React.memo(CommentEdit);
