import truncate from 'lodash/truncate';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { Popup } from '../../lib/custom-ui';

import Paths from '../../constants/Paths';
import { ActivityTypes } from '../../constants/Enums';
import User from '../User';

import styles from './NotificationsStep.module.scss';

function truncatePreservingMentions(text, maxLength) {
  let truncatedText = text.slice(0, maxLength);
  const lastOpenBracket = truncatedText.lastIndexOf('[');
  const lastCloseBracket = truncatedText.lastIndexOf(']');
  const lastParen = truncatedText.lastIndexOf(')');

  if (lastOpenBracket > lastCloseBracket) {
    truncatedText = truncatedText.slice(0, lastOpenBracket);
  }

  if (lastCloseBracket > lastParen) {
    truncatedText = truncatedText.slice(0, lastCloseBracket + 1);
  }

  return truncatedText;
}

const NotificationsStep = React.memo(({ items, onDelete, onClose }) => {
  const [t] = useTranslation();

  const handleDelete = useCallback(
    (id) => {
      onDelete(id);
    },
    [onDelete],
  );

  const renderItemContent = useCallback(
    ({ activity, card, board }) => {
      switch (activity.type) {
        case ActivityTypes.MOVE_CARD:
          return (
            <Trans
              i18nKey="common.userMovedCardFromListToList"
              values={{
                user: activity.user.name,
                card: card.name,
                fromList: activity.data.fromList.name,
                toList: activity.data.toList.name,
              }}
            >
              {activity.user.name}
              {' moved '}
              <Link to={Paths.CARDS.replace(':id', card.id)} onClick={onClose}>
                {card.name}
              </Link>
              {' from '}
              {activity.data.fromList.name}
              {' to '}
              {activity.data.toList.name}
            </Trans>
          );
        case ActivityTypes.COMMENT_CARD: {

          const regex = /\[(@[^\]]+)\]\(\/member\/(\d{19})\)/g;


          const maxLength = 100; // Example max length for truncation
          const truncatedText = truncatePreservingMentions(activity.data.text, maxLength);

          // Convert the iterator to an array and match all mentions in the truncated comment text
          const mentions = Array.from(truncatedText.matchAll(regex)) || [];

          // Create an array to hold the comment text with mentions as React elements
          const commentElements = [];
          let lastIndex = 0;

          mentions.forEach(mention => {
            const [fullMatch, name, userId] = mention;
            const matchIndex = mention.index;

            // Push text before the mention
            commentElements.push(truncatedText.slice(lastIndex, matchIndex));

            // Push the mention as a link
            commentElements.push(
              <a href={`/member/${userId}`} key={userId}>
                {name}
              </a>
            );

            lastIndex = matchIndex + fullMatch.length;
          });

          // Push any remaining text after the last mention
          if (lastIndex < truncatedText.length) {
            commentElements.push(truncatedText.slice(lastIndex));
          }

          return (
            <div>
              {activity.user.name} left a new comment «{commentElements}» to{' '}
              <Link to={Paths.CARDS.replace(':id', card.id)} onClick={onClose}>
                {card.name}
              </Link>
            </div>
          );
        }
        case ActivityTypes.MENTION_USER:
          return (
            <div>
              {activity.user.name}
              {` mentioned you on `}
              <Link to={Paths.CARDS.replace(':id', card.id)} onClick={onClose}>
                {card.name}
              </Link>
              {` in `}
              <Link to={Paths.BOARDS.replace(':id', board.id)} onClick={onClose}>
                {board.name}
              </Link>
            </div>
          )
        default:
      }

      return null;
    },
    [onClose],
  );


  return (
    <>
      <Popup.Header>
        {t('common.notifications', {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        {items.length > 0 ? (
          <div className={styles.wrapper}>
            {items.map((item) => (
              <div key={item.id} className={styles.item}>
                {item.card && item.activity && item.board ? (
                  <>
                    <User
                      name={item.activity.user.name}
                      avatarUrl={item.activity.user.avatarUrl}
                      size="large"
                    />
                    <span className={styles.itemContent}>{renderItemContent(item)}</span>
                  </>
                ) : (
                  <div className={styles.itemDeleted}>{t('common.cardOrActionAreDeleted')}</div>
                )}
                <Button
                  type="button"
                  icon="trash alternate outline"
                  className={styles.itemButton}
                  onClick={() => handleDelete(item.id)}
                />
              </div>
            ))}
          </div>
        ) : (
          t('common.noUnreadNotifications')
        )}
      </Popup.Content>
    </>
  );
});

NotificationsStep.propTypes = {
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NotificationsStep;
